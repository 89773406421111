import React from 'react'
import Img from '../images/pro.png';
import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";

const Sponsor = () => {
  return (
    <div className='main_wrap'>
      <div className='intro'>
        <div className='visual'>
          <strong className='title'>후원하기</strong>
          <span className='img'>
              <img src={Img} alt="" />
          </span>
        </div>
      </div>
      준비중입니다.
      {/* 
      <div className='sponser_wrap'>
        <p className='title'><ImQuotesLeft className='quo'/>매월 정기적 후원, 일시적 후원, 특별 후원을 하실 수 있습니다. 당신의 후원을 통해 더멋진 세상이 펼쳐집니다.<ImQuotesRight className='quo'/></p>
        <div className='sec1'>
          <ul>
            <li>후원계좌
              <div>
                <h3 className='num'>00은행 0000-000-00000</h3>
                <p>예금주 : (사)대한청년을세계로</p>
              </div>
            </li>
            <li>결제방법
            <table>
              <thead>
                <tr>
                  <th>정기후원</th>
                  <td>은행 자동이체, 신용카드</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>일시후원</th>
                  <td>
                    신용카드, 페이코, 카카오페이, 휴대폰 소액결제
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <strong>무통장 입금:</strong>
                    
                    <span className='s-txt'>정확한 확인을 위하여, 입금자명에 이름, 생년월일을 기입해 주세요.</span>
                    
                    <em className='s-txt'>예) 00000000 홍길동</em>
                  </td>
                </tr>
              </tbody>
            </table>
            </li>
            <li>후원금 납부
              <p>매월 지정일자 5일에 자동 출금됩니다.</p>
              <p>미 출금 시 그 다음 달에 재 출금이 있습니다.</p>
            </li>
            <li>기부금영수증 발급 안내
              <p>모든 후원금은 지정기부금(40)으로 처리됩니다.</p>
              <p>후원자 본인 명의로만 발급이 가능합니다.</p>
              <p>주민등록번호 13자리가 등록된 경우에만 국세청 연말정산간소화 서비스가 가능합니다.</p>
              <p>나의 후원에서 기부금 영수증 출력 하실수 있습니다.</p>
            </li>
            <li>후원문의
              <p className='num'>042) 0000-0000</p>
              <p>상담시간: 월~금, 9시~18시</p>
            </li>
          </ul>
        </div>
      </div>
      */}
    </div>
  )
}

export default Sponsor
