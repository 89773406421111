import React from 'react';
import Img from '../images/pro.png';
import Img2 from '../images/gc.png';
import mk01 from '../images/mk01.jpg';
import jh01 from '../images/jh01.jpg';
import yh01 from '../images/yh01.jpg';
import hn01 from '../images/hn01.jpg';
import cw01 from '../images/cw01.jpg';
import jb01 from '../images/jb01.jpg';

const BoardMembers = () => {
  return (
    <div className='main_wrap'>
      <div className='intro'>
        <div className='visual'>
          <strong className='title'>구성-임원명단</strong>
          <span className='img'>
              <img src={Img} alt="" />
          </span>
        </div>
      </div>
      <div className='vision_wrap'>

        <div className='table_wrap'>
          <div className='table img'>
          <h4>임원명단</h4>
          <table>
            <caption>
              <p>임원명단</p>
              성명, 소속 및 직책을 포함합니다.
            </caption>
            <colgroup>
              <col style={{ width: "25%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "25%" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope='col'></th> 
                <th scope='col'>성명</th> 
                <th scope='col'>직책</th> 
                <th scope='col'>직업</th>
              </tr>
            </thead>
            <tbody>
              <tr className='tbody_tr'>
                <td className='img_tr scale-none'><img src={mk01} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>정민기</td> 
                  <td className='td2'>이사장</td>
                  <td>(사)대한청년을세계로 이사장</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>문형남</td> 
                  <td className='td2'>고문</td>
                  <td>숙명여대 글로벌융합대학 학장</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>양진영</td> 
                  <td className='td2'>고문</td>
                  <td>(주)영아이티 대표</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>홍성호</td> 
                  <td className='td2'>부회장</td>
                  <td>(주)RPM 사내이사</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>임한세</td> 
                  <td className='td2'>법인이사</td>
                  <td>클린에어 대표</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>하범수</td> 
                  <td className='td2'>법인이사</td>
                  <td>기초단체장 비서관</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>곽문권</td> 
                  <td className='td2'>사외이사</td>
                  <td>지방의회 비서관</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>김승태</td> 
                  <td className='td2'>사외이사</td>
                  <td>(주)오름 대표이사</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>김진태</td> 
                  <td className='td2'>사외이사</td>
                  <td>대전 범맥주 대표</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr scale-none'><img src={cw01} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>문찬우</td> 
                  <td className='td2'>사외이사</td>
                  <td>전 국회 선임비서관</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>윤대영</td> 
                  <td className='td2'>사외이사</td>
                  <td></td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>윤영재</td> 
                  <td className='td2'>사외이사</td>
                  <td></td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>이상탁</td> 
                  <td className='td2'>사외이사</td>
                  <td>가감승제 대표</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={hn01} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>이하늘</td> 
                  <td className='td2'>사외이사</td>
                  <td>엔젤짐 대표</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={jb01} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>이준병</td> 
                  <td className='td2'>사외이사</td>
                  <td>청춘정거장 센터장</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>이준수</td> 
                  <td className='td2'>사외이사</td>
                  <td></td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>전성욱</td> 
                  <td className='td2'>사외이사</td>
                  <td>메리츠화재 RCM 본부장</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={jh01} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>최정현</td> 
                  <td className='td2'>사외이사</td>
                  <td>청춘포털(청년센터) 센터장</td>
              
              </tr>
               <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
                
                  <td className='td1'>이재권</td> 
                  <td className='td2'>사외이사</td>
                  <td></td>
                
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>노병준</td> 
                  <td className='td2'>전문이사</td>
                  <td>순천향대학교 교수</td>
              
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={yh01} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
              
                  <td className='td1'>이영호</td> 
                  <td className='td2'>법인감사</td>
                  <td>지방의회 비서관</td>
              
              </tr>
            </tbody>
          </table>

          </div>
        </div>
      </div>
    </div>
  )
}

export default BoardMembers
