import React from 'react'
import { Link } from 'react-router-dom'
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { RiKakaoTalkFill } from "react-icons/ri";
const Footer = () => {
  return (
    <footer>
      <div className='footer'>
        <div className='main_wrap'>
          <div className='f-top'>
            <div className='t-top-l'>
            (사)대한청년을세계로
            </div>
            <div className='t-top-r'>
              <span><a href='https://www.instagram.com/dae_cheong_se/?igsh=MWtraDk0emY0Mms0bg%3D%3D#' target="_blank" rel="noopener noreferrer"><FaInstagram /></a></span>
              <span><Link to='/'><FaFacebookSquare /></Link></span>
              <span><Link to='/'><FaYoutube /></Link></span>
              <span><a href="http://pf.kakao.com/_zxixeAn" target="_blank" rel="noopener noreferrer"><RiKakaoTalkFill /></a></span>
            </div>
          </div>
          <div className='f-bottom'>
            <p>(35233) 대전광역시 서구 둔산로 63, 403-225</p>
            <span>대표 정민기</span>
            <span>TEL 042) 710-2177</span>
            {/* <span>FAX 042) 350-0353</span> */}
            <p className='pwr'>Powered by SmartCreator Inc.</p>
          </div>
          <div className='copyright'>
            Copyrights &copy; 2024 (사)대한청년을세계로. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
    /*Main페이지는 사이트 https://www.lh.or.kr 에서 영감을 받아 제작하였습니다.*/
  )
}

export default Footer