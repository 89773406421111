import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import Img from '../images/pro.png';

const SponsorAppli = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    subject: "",
    message: "",
  });

  const [isSending, setIsSending] = useState(false);
  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, phone, subject, message } = formData;
    if (!name || !email || !phone || !subject || !message) {
      alert("모든 필수 항목을 입력해주세요.");
      return;
    }

    setIsSending(true);

    try {
      const form = new FormData(formRef.current);
      form.append("address", formData.address || "N/A");

      const response = await emailjs.sendForm(
        "yeseonan",  
        "template_jr0t6sd", 
        formRef.current,  
        "t_vKKY-0dXFexB0yI" 
      );

      console.log('성공적으로 전송되었습니다:', response);
      alert("문의가 성공적으로 전송되었습니다!");

      window.location.reload();
    } catch (error) {
      console.error("전송 중 오류 발생:", error);
      alert("문의 전송에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className='main_wrap'>
      <div className='intro'>
        <div className='visual'>
          <strong className='title'>문의하기</strong>
          <span className='img'>
              <img src={Img} alt="" />
          </span>
        </div>
        준비중입니다.
        {/* 
        <p>대한청년을 세계로 궁금한 사항을 문의해 주세요.
최대한 빠른 시일 내에 친절하게 답변해드리겠습니다.
(토,일,공휴일 문의는 평일 운영시간 순차적으로 답변됩니다)</p>
        <form className="appli_form" ref={formRef} onSubmit={handleSubmit} style={{ margin: "0 auto" }}>
          <div>
            <label>이름 (필수)</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="이름을 입력하세요"
              required
            />
          </div>
          <div>
            <label>이메일 (필수)</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="이메일을 입력하세요"
              required
            />
          </div>
          <div>
            <label>전화번호 (필수)</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="전화번호를 입력하세요"
              required
            />
          </div>
          <div>
            <label>주소 (선택)</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="주소를 입력하세요"
            />
          </div>
          <div>
            <label>제목 (필수)</label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="제목을 입력하세요"
              required
            />
          </div>
          <div>
            <label>내용 (필수)</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="문의 내용을 입력하세요"
              required
            ></textarea>
          </div>
          <button type="submit" disabled={isSending}>
            {isSending ? "전송 중..." : "문의하기"}
          </button>
        </form>
        */}
      </div>
    </div>
  );
};

export default SponsorAppli;
