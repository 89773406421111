import React from 'react'
import Img from '../images/pro.png'
import Img2 from '../images/gc.png'

const ForigenOffice = () => {
  return (
    <div className='main_wrap'>
    <div className='intro'>
      <div className='visual'>
        <strong className='title'>구성-해외지부</strong>
        <span className='img'>
            <img src={Img} alt="" />
        </span>
      </div>
    </div>
    <div className='vision_wrap'>
      <div className='table_wrap'>
        <div className='table img'>
        <h4>해외지부</h4>
        <table>
          <caption>
            <p>해외지부</p>
            성명, 소속 및 직책을 포함합니다.
          </caption>
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <thead>
            <tr>
              <th scope='col'></th> 
              <th scope='col'>지부</th> 
              <th scope='col'>성명</th> 
              <th scope='col'>직책</th> 
              <th scope='col'>직업</th>
            </tr>
          </thead>
          <tbody>
            <tr className='tbody_tr'>
              <td className='img_tr2'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy"/></td>
              <td className='td1'>해외지부(Florida Branch)</td>
              <td className='td3'>Christian Faulkner</td> 
              <td>Florida Branch Manager</td>
              <td>Computer Engineering at Embry-Riddle Aeronautical University</td>
            </tr>
          </tbody>
        </table>

        </div>
      </div>
    </div>
  </div>
  )
}

export default ForigenOffice