import React from 'react'
import Img from '../images/pro.png'
import Img2 from '../images/gc.png'
import jh01 from '../images/jh01.jpg'
import jh02 from '../images/jh02.jpg'

const Vision = () => {
  return (
    <div className='main_wrap'>
      <div className='intro'>
        <div className='visual'>
          <strong className='title'>구성-위원회</strong>
          <span className='img'>
              <img src={Img} alt="" />
          </span>
        </div>
      </div>
      <div className='vision_wrap'>
      <div className='table_wrap'>
          <div className='table img'>
          <h4>위원회</h4>
          <table>
            <caption>
              <p>위원회</p>
              성명, 소속 및 직책을 포함합니다.
            </caption>
            <colgroup>
              <col style={{ width: "25%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "35%" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope='col'></th> 
                <th scope='col'>성명</th> 
                <th scope='col'>직책</th> 
                <th scope='col'>직업</th>
              </tr>
            </thead>
            <tbody>
              <tr className='tbody_tr one_td'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
                  <td className='td1'></td> 
                  <td className='td2'>대학AI혁신위원회 위원장</td>
                  <td></td>
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={jh02} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
                  <td className='td1'>최정현</td> 
                  <td className='td2'>AI제조/서비스노동위원회 위원장</td>
                  <td>전 금속노조 민주노총 기아차지부 지회장</td>
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={jh01} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
                  <td className='td1'>최정현</td> 
                  <td className='td2'>취창업AI혁신위원회 위원장</td>
                  <td>청춘포털(청년센터) 센터장</td>
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
                  <td className='td1'>김창명</td> 
                  <td className='td2'>소상공AI혁신위원회 위원장</td>
                  <td>치타마켓 대표이사</td>
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
                  <td className='td1'>전성욱</td> 
                  <td className='td2'>세일즈AI혁신위원회</td>
                  <td>메리츠화재 RCM 본부장</td>
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}}  loading="lazy" /></td>
                  <td className='td1'>채승민(Shine)</td> 
                  <td className='td2'>글로벌위원회 위원장</td>
                  <td>University of South Florida Honors Biomedical Sciences</td>
              </tr>
            </tbody>
          </table>


        </div>
      </div>
      </div>
      
    </div>
  )
}

export default Vision
