import React from 'react'
import Img from '../images/pro.png'
import Img2 from '../images/gc.png'
import cw01 from '../images/cw01.jpg'

const RegionalOffices = () => {
  return (
    <div className='main_wrap'>
      <div className='intro'>
        <div className='visual'>
          <strong className='title'>구성-지부</strong>
          <span className='img'>
              <img src={Img} alt="" />
          </span>
        </div>
      </div>
      <div className='vision_wrap'>
        <div className='table_wrap'>
          <div className='table img'>
          <h4>지부</h4>
          <table>
            <caption>
              <p>지부</p>
              성명, 소속 및 직책을 포함합니다.
            </caption>
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <thead>
              <tr className='tbody_tr'>
                <th scope='col'></th> 
                <th scope='col'>지부</th> 
                <th scope='col'>성명</th> 
                <th scope='col'>직책</th> 
                <th scope='col'>직업</th>
              </tr>
            </thead>
            <tbody>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}} loading="lazy"/></td>
                
                  <td className='td1'>대전본부</td>
                  <td className='td2'>홍성호</td> 
                  <td>대전본부 본부장</td>
                  <td>(주)RPM 사내이사</td>
                
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}} loading="lazy"/></td>
                
                  <td className='td1'>서울지부</td>
                  <td className='td2'></td> 
                  <td></td>
                  <td></td>
                
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={cw01} alt='인물사진' style={{width: 144, height: 185}} loading="lazy" /></td>
                
                  <td className='td1'>세종지부</td>
                  <td className='td2'>문찬우</td> 
                  <td>세종지부 지부장</td>
                  <td>전 국회 선임비서관</td>
                
              </tr>
              <tr className='tbody_tr'>
                <td className='img_tr'><img src={Img2} alt='인물사진' style={{width: 144, height: 185}} loading="lazy" /></td>
                
                  <td className='td1'>충청지부</td>
                  <td className='td2'></td> 
                  <td></td>
                  <td></td>
                
              </tr>
            </tbody>
          </table>

          </div>
        </div>
      </div>
    </div>
  )
}

export default RegionalOffices
