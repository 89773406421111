import React from 'react'
import Img from '../images/pro.png'
import Img2 from '../images/organic01.png'

const OrganizationChart = () => {
  return (
    <div className='main_wrap'>
      <div className='intro'>
        <div className='visual'>
          <strong className='title'>소개-조직도</strong>
          <span className='img'>
              <img src={Img} alt=''/>
          </span>
        </div>
      </div>
      <div className='oraniztion_wrap'>
        <p className='oraniztion_tit'>ORGANICZATION</p>
        <div className='vision_wrap'>
          <img src={Img2} alt="조직도" loading="lazy" />
        </div>
      </div>
    </div>
  )
}

export default OrganizationChart
