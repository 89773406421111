import React from 'react'
import Img from '../images/pro.png';
import Img3 from '../images/intro_logo01.jpg'
import Img4 from '../images/vision.png'

const Summary = () => {
  return (
    <div className='main_wrap'>
      <div className='intro'>
        <div className='visual'>
          <strong className='title'>소개-개요</strong>
          <span className='img'>
              <img src={Img} alt="" />
          </span>
        </div>
      </div>
      <div className='summary_wrap'>
        <div className='inner'>
          <div className='ask'>
          <h1 className='mb-5'>법인 개요</h1>
          (사)대한청년을세계로는 급변하는 기술 혁신과 글로벌 트렌드 속에서 청년들이 미래를 주도적으로 설계할 수 있도록 지원하기 위해 설립된 비영리법인입니다. 이 법인은 청년들이 기술과 인지의 간극을 극복하고 글로벌 리더로 성장할 수 있는 다양한 기회를 제공하며, 이를 위해 국내외 네트워크를 적극적으로 구축하고 있습니다.
          <br/><br/>
          특히, 법인은 산업별 AI 기술의 미래를 탐구하며, 각 산업과 기술의 상관관계를 분석해 청년들이 변화하는 경제 및 기술 환경 속에서 실질적인 방향성을 찾을 수 있도록 돕고 있습니다. 이를 통해 청년들이 혁신 기술을 활용하여 사회적 문제를 해결하고, 다양한 산업에서의 경쟁력을 강화할 수 있도록 지원하고 있습니다.
          <br/><br/>
          주요 활동으로는 미래전략포럼을 중심으로 한 청년 역량 강화 프로그램과 글로벌 교류 프로젝트가 있습니다. 법인은 청년들이 지역 사회와 연계된 프로젝트를 통해 국내에서의 경쟁력을 강화하는 동시에 국제적 네트워크를 확장해 나가고 있습니다.
          <br/><br/>
          (사)대한청년을세계로는 청년들이 변화하는 사회와 기술 환경 속에서 주체적으로 행동하며 글로벌 무대에서 활약할 수 있는 리더로 성장할 수 있도록 지원하는 것을 비전으로 삼고 있습니다. 이를 위해 법인은 지속 가능한 청년 지원 환경을 조성하고, 다양한 교육과 교류 프로그램을 통해 미래 사회를 선도할 인재 양성에 앞장서고 있습니다.
          </div>
          <div className='img'>
          <img src={Img3} alt="" />
        </div>
        </div>
      </div>
      {/* 
      <div className='vision_wrap'>
        <img src={Img4} alt="목표와 경영비전" />
      </div>
      */}
    </div>
  )
}

export default Summary
