import React, { useEffect, useState } from 'react';
import axios from 'axios';
import proImg from '../images/pro.png';
import { useParams } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowRight, MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { FaRegFileArchive } from "react-icons/fa";

const Notice = () => {
  const [boards, setBoards] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchType, setSearchType] = useState('title');
  const [filesExistence, setFilesExistence] = useState({});
  const [loadingBoards, setLoadingBoards] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hits, setHits] = useState({}); // 조회수 데이터
  const itemsPerPage = 3;
  const { role } = useParams();

  const CACHE_KEY = `boards_${role}`;
const CACHE_EXPIRATION = 30 * 60 * 1000; // 30분

  useEffect(() => {
    fetchBoards();
  }, [role]);

  useEffect(() => {
    updateHits(); // 조회수 실시간 업데이트
  }, [boards]);

  const fetchBoards = async () => {
    setLoading(true);
  
    try {
      // 1. 로컬 스토리지에서 캐싱된 데이터 확인
      const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
      const now = new Date().getTime();
  
      if (cachedData && now - cachedData.timestamp < CACHE_EXPIRATION) {
        // 캐싱 데이터가 유효하면 사용
        console.log('Using cached data');
        setBoards(cachedData.boards);
        setTotalPosts(cachedData.boards.length);
        setTotalPages(Math.ceil(cachedData.boards.length / itemsPerPage));
        setFilesExistence(cachedData.fileStatus);
      } else {
        // 2. 서버에서 데이터 가져오기
        const response = await axios.get(
          `https://dcs-site-5dccc5b2f0e4.herokuapp.com/api/board/role/${role}`,
          { withCredentials: true }
        );
  
        const sortedBoards = response.data
          .sort((a, b) => {
            if (a.isnotice === '공지' && b.isnotice !== '공지') return -1;
            if (a.isnotice !== '공지' && b.isnotice === '공지') return 1;
            return b.id - a.id;
          })
          .map((item, index) => ({
            ...item,
            displayNumber: response.data.length - index,
          }));
  
        const fileStatus = await fetchFilesExistence(sortedBoards);
  
        // 상태 업데이트
        setBoards(sortedBoards);
        setTotalPosts(response.data.length);
        setTotalPages(Math.ceil(response.data.length / itemsPerPage));
        setFilesExistence(fileStatus);
  
        // 3. 캐싱 데이터 저장
        localStorage.setItem(
          CACHE_KEY,
          JSON.stringify({ boards: sortedBoards, fileStatus, timestamp: now })
        );
      }
    } catch (error) {
      console.error('Failed to fetch boards:', error.response || error.message);
      alert('게시판 데이터를 불러오는 데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };
  // 각 게시글의 조회수를 서버에서 가져옴 (실시간)
  const updateHits = async () => {
    try {
      const hitUpdates = await Promise.all(
        boards.map((board) =>
          axios
            .get(`https://dcs-site-5dccc5b2f0e4.herokuapp.com/api/board/${board.id}/hit`, { withCredentials: true })
            .then((res) => ({ [board.id]: res.data }))
            .catch((error) => {
              console.error(`Failed to fetch hit for board ${board.id}:`, error);
              return { [board.id]: 0 };
            })
        )
      );
      setHits(Object.assign({}, ...hitUpdates));
    } catch (error) {
      console.error('조회수 업데이트 실패:', error);
    }
  };

  const fetchFilesExistence = async (boards) => {
    try {
      const results = await Promise.all(
        boards.map((board) =>
          axios
            .get(`https://dcs-site-5dccc5b2f0e4.herokuapp.com/api/files/board/${board.id}/hasFile`, {
              withCredentials: true,
            })
            .then((response) => ({ [board.id]: response.data }))
            .catch(() => ({ [board.id]: false }))
        )
      );
      return Object.assign({}, ...results);
    } catch (error) {
      console.error('Failed to fetch file existence: ', error);
      return {};
    }
  };

  const handlePageChange = (page) => setCurrentPage(page); 

  const handleSearch = async (e) => {
    e.preventDefault();
    const searchValue = e.target.searchvalue.value;
  
    setLoadingBoards(true);
    try {
      let response;
      
      if (searchValue.trim() === "") {
        // 검색어가 공백인 경우 원본 데이터를 다시 불러오기
        response = await axios.get(
          `https://dcs-site-5dccc5b2f0e4.herokuapp.com/api/board/role/${role}`,
          {
            withCredentials: true,
          }
        );
      } else {
        // 검색어가 있을 경우 검색
        response = await axios.get(
          `https://dcs-site-5dccc5b2f0e4.herokuapp.com/api/board/role/${role}/search`,
          {
            params: { searchType, searchValue },
            withCredentials: true,
          }
        );
      }
  
      // 공지글을 먼저 두고, 나머지는 최신순으로 정렬
      const sortedBoards = response.data
        .sort((a, b) => {
          if (a.isnotice === '공지' && b.isnotice !== '공지') return -1;
          if (a.isnotice !== '공지' && b.isnotice === '공지') return 1;
          return b.id - a.id; // 최신순으로 정렬
        })
        .map((item, index) => ({
          ...item,
          displayNumber: response.data.length - index, // 순번 재설정
        }));
  
      // 파일 존재 여부 체크
      const fileStatus = await fetchFilesExistence(sortedBoards);
  
      setBoards(sortedBoards); // 상태 업데이트
      setTotalPosts(response.data.length);
      setTotalPages(Math.ceil(response.data.length / itemsPerPage));
      setFilesExistence(fileStatus); // 파일 존재 여부 상태 업데이트
      setCurrentPage(0); // 페이지 초기화
    } catch (error) {
      console.error('Failed to search:', error.response?.data || error.message);
      alert('검색 중 오류가 발생했습니다.');
    } finally {
      setLoadingBoards(false);
    }
  };

  const currentBoards = boards.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);


  return (
    <div className="main_wrap">
      <div className="intro">
        <div className="visual">
          <strong className="title">
            {role === '1' && '공지사항'}
            {role === '2' && 'Q & A'}
            {role === '3' && '활동사진'}
            {role === '4' && '언론보도'}
            {role === '5' && '미래전략포럼'}
            {role === '6' && 'AI혁신위원회'}
            {role === '7' && '글로벌 네트워킹'}
            {role === '8' && '지역 청년 네트워킹'}
          </strong>
          <span className="img">
            <img
              src={role ? proImg : '../images/default_visual.jpg'}
              alt="Role Image"
            />
          </span>
        </div>
      </div>

      <div className="listbox mt-5">
        <div className="total-pg-info">
          <p className='me-3'>전체 게시글 수: {totalPosts}</p>
          <p>총 페이지 수: {totalPages}</p>
        </div>
        <table className="table table-box">
          <thead>
            <tr>
              <th>번호</th>
              <th>제목</th>
              <th>글쓴이</th>
              <th>날짜</th>
              <th>조회수</th>
            </tr>
          </thead>
          <tbody>
          {loading ? (
            <tr>
              <td colSpan="5" style={{ textAlign: 'center' }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          ) : currentBoards.length > 0 ? (
            currentBoards.map((board) => (
              <tr key={board.id}>
                <td data-label="번호:">{board.isnotice === '공지' ? '공지' : board.displayNumber}</td>
                <td data-label="제목:" className="filetd">
                  <a href={`/board/${role}/${board.id}`}>{board.title}</a>
                  {filesExistence[board.id] && <span className="file_font"><FaRegFileArchive /></span>}
                </td>
                <td data-label="작성자:">{board.writer}</td>
                <td data-label="작성시간:">{board.bbsCreatedTime}</td>
                <td data-label="조회수:">{hits[board.id]}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: 'center' }}>게시글이 없습니다.</td>
            </tr>
          )}
        </tbody>
        </table>
          
        <div className="pagination paging pt-3">
  {/* 맨 처음 페이지로 이동 */}
  <a 
    className="page-link" 
    onClick={() => {
      if (currentBoards.length > 0) handlePageChange(0);
    }} 
    disabled={currentPage === 0 || currentBoards.length === 0}
  >
    <MdKeyboardDoubleArrowLeft />
  </a>

  {/* 이전 페이지로 이동 */}
  <a 
    className="page-link" 
    onClick={() => {
      if (currentBoards.length > 0 && currentPage > 0) {
        handlePageChange(currentPage - 1);
      }
    }} 
    disabled={currentPage <= 0 || currentBoards.length === 0}
  >
    <MdKeyboardArrowLeft />
  </a>

  {/* 페이지 번호 */}
  {Array.from({ length: totalPages }, (_, index) => (
    <a
      key={index}
      className={`page-link ${currentPage === index ? 'active' : ''}`}
      onClick={() => {
        if (currentBoards.length > 0) handlePageChange(index);
      }}
    >
      {index + 1}
    </a>
  ))}

  {/* 다음 페이지로 이동 */}
  <a 
    className="page-link" 
    onClick={() => {
      if (currentBoards.length > 0 && currentPage < totalPages - 1) {
        handlePageChange(currentPage + 1);
      }
    }} 
    disabled={currentPage >= totalPages - 1 || currentBoards.length === 0}
  >
    <MdKeyboardArrowRight />
  </a>

  {/* 맨 마지막 페이지로 이동 */}
  <a 
    className="page-link" 
    onClick={() => {
      if (currentBoards.length > 0) handlePageChange(totalPages - 1);
    }} 
    disabled={currentPage === totalPages - 1 || currentBoards.length === 0}
  >
    <MdKeyboardDoubleArrowRight />
  </a>
</div>
          
          <form name="searchform" id="searchform" className="searchform" method="get" onSubmit={handleSearch}>
            <div className="input-group my-3">
              <div className="input-group-prepend">
                <button 
                  type="button" 
                  className="btn btn-outline-secondary dropdown-toggle" 
                  onClick={toggleDropdown}
                >
                  {searchType === 'title' ? '제목검색' : searchType === 'writer' ? '이름검색' : '내용검색'}
                </button>
                {dropdownOpen && (
                  <div className="dropdown-menu show">
                    <a className="dropdown-item" onClick={() => { setSearchType('writer'); setDropdownOpen(false); }}>이름검색</a>
                    <a className="dropdown-item" onClick={() => { setSearchType('title'); setDropdownOpen(false); }}>제목검색</a>
                    <a className="dropdown-item" onClick={() => { setSearchType('content'); setDropdownOpen(false); }}>내용검색</a>
                  </div>
                )}
              </div>
              <input type="hidden" name="searchname" id="searchname" value={searchType} />
              <input type="search" name="searchvalue" className="form-control" placeholder="검색" />
              <div className="input-group-append">
                <button className="btn btn-primary"><i className="ri-search-line"></i></button>
              </div>
            </div>
          </form>
        </div>
   </div>
  );
};

export default Notice;
